import store from '@/store';
import { supabase } from '@/supabase';
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTasks(ctx) {
      return new Promise((resolve, reject) => {
        supabase.from("daily-task").select().eq("is_deleted", false).eq("user_id", store.user.id)
          .then(({ data, error }) => {
            if (error) {
              console.log('error in fetchTasks ', error);
              reject(error)
            }
            resolve(data)
          });
      })
    },
    addTask(ctx, task) {
      return new Promise((resolve, reject) => {
        task.user_id = store.user.id;
        delete task.id;
        const data = {
          assignee: task.assignee,
          check_in_time: task.check_in_time,
          created_at: task.created_at,
          date: task.date,
          day_end_tasks: task.day_end_tasks,
          day_start_tasks: task.day_start_tasks,
          is_completed: task.is_completed,
          is_deleted: task.is_deleted,
          is_important: task.is_important,
          issues: task.issues,
          log_out_time: task.log_out_time,
          project_name: task.project_name,
          tags: task.tags,
          user_id: task.user_id,
        }
        supabase.from("daily-task").insert(data).then(({ data, error }) => {
          if (error) {
            console.log('Error in adding task ', error);
            reject()
          }
          resolve(data);
        });
      })
    },
    updateTask(ctx, { task }) {
      return new Promise((resolve, reject) => {
        let task_id = task.id;
        delete task.id;
        const data = {
          assignee: task.assignee,
          check_in_time: task.check_in_time,
          created_at: task.created_at,
          date: task.date,
          day_end_tasks: task.day_end_tasks,
          day_start_tasks: task.day_start_tasks,
          is_completed: task.is_completed,
          is_deleted: task.is_deleted,
          is_important: task.is_important,
          issues: task.issues,
          log_out_time: task.log_out_time,
          project_name: task.project_name,
          tags: task.tags,
          user_id: task.user_id,
        }
        supabase.from("daily-task").update(data).eq('id', task_id).then(({ data, error }) => {
          if (error) {
            console.log('error in upating task ', error);
            reject()
          }
          resolve(data);
        });
      })
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        supabase.from("daily-task").update({ "is_deleted": true }).eq('id', id).then(({ data, error }) => {
          if (error) {
            console.log('error in deleting task ', error);
            reject()
          }
          resolve(data);
        });
      })
    },
  },
}
